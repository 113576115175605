<template>
    <div class="wrapper" v-bind="$attrs">
        <transition-group name="slide-fade">
            <div v-if="message" class="message">
                {{ message }}
            </div>
        </transition-group>
    </div>
</template>
<script>
export default {
    props: {
        message: String
    }
}
</script>
<style lang="scss" scoped>
.wrapper {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    pointer-events: none;

    .message {
        overflow: auto;
        max-height: 100%;
        color: white;
        padding: 24px 40px;
        width: 360px;
        max-width: 100%;
        background: #3c4043;
        border-radius: 8px;
        text-align: center;
        font-size: 32px;
        font-weight: bolder;
        box-shadow: rgba(0, 0, 0, .5) 0px 3px 8px 0px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.3) 0px 4px 4px 0px;
    }
}

.close {
    flex-shrink: 0;
    margin-right: -12px;
}
</style>

<style lang="scss" scoped>
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all 0.2s ease-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: scale(.85);
        opacity: 0;
    }

    .slide-move {
        transition: transform 0.8s ease;
    }
</style>
