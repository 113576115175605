<template>
    <div class="page">
        <header>
            <template v-if="!record.state.matches('recording')">
                <w-button background="#202124" to="/" class="back-button icon"
                    ><icon :icon="icons.closeIcon" height="24"
                /></w-button>
                <w-button
                    background="#202124"
                    @click="startOnboarding"
                    class="back-button icon"
                    ><icon :icon="icons.roundQuestionMark" height="24"
                /></w-button>
            </template>
            <template v-else>
                <w-button
                    background="#202124"
                    @click="record.send('RESET')"
                    class="back-button"
                    ><icon :icon="icons.removeCircle" height="20" />Cancel
                    recording</w-button
                >
                <div class="timer">
                    <span
                        class="timer"
                        v-if="record.state.matches('recording')"
                    >
                        {{ stopWatch }}
                        <icon
                            style="color: red"
                            :icon="icons.record"
                            height="24"
                        />
                    </span>
                </div>
            </template>
        </header>
        <div v-show="desktopActive || webcamActive || audioActive" class="main">
            <div
                ref="viewport"
                class="preview-wrapper"
                :class="{ hide: !canvasVisibility }"
                @dblclick="toggleVisibility"
            >
                <video ref="webcam-video" mute autoplay="true"></video>
                <video ref="desktop-video" mute autoplay="true"></video>
                <canvas style="display: none" ref="tempCanvas"></canvas>
                <canvas style="display: none" ref="mirror"></canvas>
                <canvas
                    ref="canvas"
                    class="preview"
                    v-bind="aspectRatio"
                    :style="{
                        '--scale': viewportScale,
                        '--adjustX': viewportOverlap,
                    }"
                />
                <!-- <canvas
                    ref="canvas"
                    v-bind="aspectRatio"
                /> -->
            </div>
            <auto-transcribe
                v-if="settings.autoTranscribe && audioActive"
                :test="setViewportSize()"
                :recording="recordingState === 'recording'"
                class="transcript"
            />
        </div>
        <div
            v-if="!desktopActive && !webcamActive && !audioActive"
            class="prepare"
        >
            <h2>🙈</h2>
            <h2>Nothing to record</h2>
            <p>Turn on your camera or share something to start recording</p>
            <w-button @click="desktop.send('TOGGLE')">Share screen</w-button>
        </div>
        <footer>
            <w-button
                icon
                :class="{ active: webcam.state.matches('active') }"
                @click="webcam.send('TOGGLE')"
                :style="`--label: 'Camera'`"
            >
                <icon
                    :icon="
                        webcam.state.matches('off')
                            ? icons.cameraOff
                            : webcam.state.matches('active')
                            ? icons.camera
                            : icons.bubbleLoading
                    "
                    height="20"
                />
            </w-button>
            <w-button
                icon
                :class="{ active: desktop.state.matches('active') }"
                @click="desktop.send('TOGGLE')"
                :style="`--label: 'Screen'`"
            >
                <icon
                    :icon="
                        desktop.state.matches('off')
                            ? icons.monitorOff
                            : desktop.state.matches('active')
                            ? icons.monitor
                            : icons.bubbleLoading
                    "
                    height="20"
                />
            </w-button>
            <w-button
                icon
                :class="{ active: audio.state.matches('active') }"
                @click="audio.send('TOGGLE')"
                :disabled="!record.state.matches('off')"
                :style="`--label: 'Microphone'`"
            >
                <icon
                    :icon="
                        audio.state.matches('off')
                            ? icons.microphoneOff
                            : audio.state.matches('active')
                            ? icons.microphone
                            : icons.bubbleLoading
                    "
                    height="20"
                />
            </w-button>
            <w-button
                icon
                background="#3c4043"
                @click="optionsModal = true"
                :style="`--label: 'Settings'`"
                ><icon :icon="icons.tune" height="20"
            /></w-button>
            <w-button
                class="recording-button"
                background="#f44336"
                @click="record.send('TOGGLE')"
                light
                :disabled="!desktopActive && !webcamActive && !audioActive"
            >
                <icon :icon="icons.record" height="24" />
                {{ record.state.matches("recording") ? "Stop" : "Start" }}
            </w-button>
        </footer>
    </div>
    <w-prompt :message="messaging" />
    <w-modal
        :open="optionsModal"
        @close="optionsModal = false"
        @backgroundClick="optionsModal = false"
        floatingClose
    >
        <template #modal-header>
            <div class="_header">More settings</div>
        </template>
        <div class="_body">
            <div class="form-element">
                <div class="group">
                    <icon :icon="icons.micIcon" height="22" />
                    <div class="label">Background</div>
                    <wSelect
                        :options="settings.backgrounds"
                        v-model="settings.background"
                    ></wSelect>
                </div>
            </div>
            <div class="form-element">
                <div class="group">
                    <icon :icon="icons.micIcon" height="22" />
                    <div class="label">Aspect ratio</div>
                    <wSelect
                        :options="Object.keys(settings.aspectRatios)"
                        v-model="settings.aspectRatio"
                    ></wSelect>
                </div>
            </div>
            <div class="form-element">
                <div class="group">
                    <icon :icon="icons.micIcon" height="22" />
                    <div class="label">Mirror webcam</div>
                    <w-switch
                        v-model="settings.mirrorWebcam"
                        @click="settings.mirrorWebcam = !settings.mirrorWebcam"
                    ></w-switch>
                </div>
            </div>
            <div class="form-element">
                <div class="group">
                    <icon :icon="icons.micIcon" height="22" />
                    <div class="label">Start countdown</div>
                    <w-switch
                        v-model="settings.startCountdown"
                        @click="
                            settings.startCountdown = !settings.startCountdown
                        "
                    ></w-switch>
                </div>
            </div>
            <div class="form-element">
                <div class="group">
                    <icon :icon="icons.micIcon" height="22" />
                    <div class="label">Speech to text</div>
                    <w-switch
                        v-model="settings.autoTranscribe"
                        @click="
                            settings.autoTranscribe = !settings.autoTranscribe
                        "
                    ></w-switch>
                </div>
            </div>
        </div>
    </w-modal>

    <record-onboarding ref="onboarding"></record-onboarding>
</template>
<script>
import RecordRTC, { getTracks } from "recordrtc"
import startStreamServices from "@/helpers/streams"
import aspectRatios from "@/helpers/aspectRatios"
import wButton from "@/components/wButton"
import wModal from "@/components/wModal"
import wPrompt from "@/components/wPrompt"
import wSelect from "@/components/wSelect"
import { Icon } from "@iconify/vue"
import roundHelpOutline from "@iconify-icons/ic/round-help-outline"
import closeIcon from "@iconify-icons/mdi/close"
import camera from "@iconify-icons/mdi/camera"
import cameraOff from "@iconify-icons/mdi/camera-off"
import monitor from "@iconify-icons/mdi/monitor"
import monitorOff from "@iconify-icons/mdi/monitor-off"
import microphone from "@iconify-icons/mdi/microphone"
import microphoneOff from "@iconify-icons/mdi/microphone-off"
import bubbleLoading from "@iconify-icons/eos-icons/bubble-loading"
import removeCircle from "@iconify-icons/ic/round-remove-circle-outline"
import tune from "@iconify-icons/mdi/tune"
import record from "@iconify-icons/mdi/record"
import { useMachine } from "@xstate/vue"
import { createMachine } from "xstate"
import { mapActions, mapState } from "vuex"
import WSwitch from "../components/wSwitch.vue"
import AutoTranscribe from "../components/AutoTranscribe.vue"
import RecordOnboarding from "../components/RecordOnboarding.vue"
import roundQuestionMark from "@iconify-icons/mdi/help-circle-outline"
import { getAnalytics, logEvent } from "firebase/analytics"

const analytics = getAnalytics()

const recordMachine = createMachine({
    id: "record",
    initial: "off",
    states: {
        off: {
            on: {
                TOGGLE: "starting",
            },
        },
        starting: {
            invoke: {
                src: "startRecording",
                onDone: "recording",
                onError: "off",
            },
        },
        recording: {
            on: {
                RESET: "off",
                TOGGLE: "off",
                PAUSE: "paused",
            },
        },
        paused: {
            on: {
                PAUSE: "recording",
                TOGGLE: "off",
            },
        },
    },
})

export default {
    name: "Record",
    components: {
        wModal,
        wButton,
        wSelect,
        wPrompt,
        Icon,
        WSwitch,
        AutoTranscribe,
        RecordOnboarding,
    },
    data() {
        return {
            icons: {
                removeCircle,
                roundHelpOutline,
                camera,
                cameraOff,
                monitor,
                monitorOff,
                microphone,
                microphoneOff,
                bubbleLoading,
                tune,
                record,
                closeIcon,
                roundQuestionMark,
            },
            ...startStreamServices(),
            viewportSize: {
                width: 0,
                height: 0,
            },
            backgroundImg: new Image(),
            logoImg: new Image(),
            lastActiveStreams: "",
            optionsModal: false,
            settings: {
                background: "Black",
                backgrounds: [
                    "Black",
                    "White",
                    "Gradienta2",
                    "Gradient",
                    "Tropical-day",
                    "Tropical-sunset",
                    "Tropical-night",
                    "camille-couvez",
                    "gradient-light",
                    "gradienta",
                    "scott-webb-triangle-pattern",
                ],
                aspectRatios,
                aspectRatio: "1280 x 720",
                screenPadding: 0,
                mirrorWebcam: true,
                startCountdown: false,
            },
            record: useMachine(
                recordMachine.withConfig({
                    services: {
                        startRecording: this.startRecording,
                    },
                })
            ),
            messaging: "",
            startTime: null,
            elapsedTime: null,
            canvasVisibility: true,
        }
    },
    computed: {
        ...mapState(["recorder"]),
        recordingState() {
            return this.record.state.value
        },
        desktopActive() {
            return this.desktop.state.matches("active")
        },
        webcamActive() {
            return this.webcam.state.matches("active")
        },
        audioActive() {
            return this.audio.state.matches("active")
        },
        logo() {
            return require("@/assets/logo-white.svg")
        },
        background() {
            return {
                Black: require("@/assets/solid-colors/black.svg"),
                White: require("@/assets/solid-colors/white.svg"),
                Gradient: require("@/assets/backgrounds/gradient.jpg"),
                "Tropical-day": require("@/assets/Tropical/Tropical-day/Tropical-day.png"),
                "Tropical-sunset": require("@/assets/Tropical/Tropical-sunset/Tropical-sunset.png"),
                "Tropical-night": require("@/assets/Tropical/Tropical-night/Tropical-night.png"),
                "camille-couvez": require("@/assets/backgrounds/camille-couvez.jpg"),
                "gradient-light": require("@/assets/backgrounds/gradient-light.jpg"),
                gradienta: require("@/assets/backgrounds/gradienta.jpg"),
                Gradienta2: require("@/assets/backgrounds/gradienta2.jpg"),
                "scott-webb-triangle-pattern": require("@/assets/backgrounds/scott-webb-triangle-pattern.jpg"),
            }[this.settings.background]
        },
        aspectRatio() {
            return this.settings.aspectRatios[this.settings.aspectRatio]
        },
        viewportScale() {
            return Math.min(
                this.viewportSize.width / this.aspectRatio.width,
                this.viewportSize.height / this.aspectRatio.height
            )
        },
        viewportOverlap() {
            return (
                (this.viewportSize.width - this.aspectRatio.width) / 2 + "px"
            )
        },
        videoPlaying() {
            return this.desktopActive || this.webcamActive
        },
        stopWatch() {
            const diffInHrs = (this.elapsedTime ?? 0) / 3600000
            const hh = Math.floor(diffInHrs)

            const diffInMin = (diffInHrs - hh) * 60
            const mm = Math.floor(diffInMin)

            const diffInSec = (diffInMin - mm) * 60
            const ss = Math.floor(diffInSec)
            let ret = ""
            if (hh) ret += String(hh).padStart(2, "0") + ":"
            ret += String(mm).padStart(2, "0") + ":"
            ret += String(ss).padStart(2, "0")
            return ret
        },
    },
    watch: {
        recordingState(to, from) {
            if (
                to !== "off" ||
                !["recording", "paused", "reset"].includes(from)
            ) {
                return
            }
            this.recorder.stopRecording(() => {
                if (this.webcam.state.matches("active")) {
                    this.webcam.send("TOGGLE")
                }
                if (this.desktop.state.matches("active")) {
                    this.desktop.send("TOGGLE")
                }
                if (this.audio.state.matches("active")) {
                    this.audio.send("TOGGLE")
                }
                logEvent(analytics, "stop recording", {
                    recordingLength: this.stopWatch,
                })
                if (this.record.state.event.type !== "RESET") {
                    this.$router.push("/preview")
                }
            })
        },
        desktopActive() {
            this.$refs["desktop-video"].srcObject =
                this.desktop.state.context.stream
            this.setViewportSize()
        },
        webcamActive() {
            this.$refs["webcam-video"].srcObject =
                this.webcam.state.context.stream
            this.setViewportSize()
        },
        audioActive() {
            this.setViewportSize()
        },
        videoPlaying(playing) {
            if (playing) this.render()
        },
        settings: {
            handler() {
                this.render()
                this.setViewportSize()
            },
            deep: true,
        },
    },
    mounted() {
        this.setViewportSize()
        window.addEventListener("resize", this.setViewportSize)

        this.render()
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.setViewportSize)
    },
    methods: {
        ...mapActions(["setRecorder"]),
        startOnboarding() {
            this.$refs.onboarding.tab = "start"
        },
        toggleVisibility() {
            this.canvasVisibility = !this.canvasVisibility
        },
        setViewportSize() {
            requestAnimationFrame(() => {
                if (!this.$refs.viewport) return
                this.viewportSize.width = this.$refs.viewport.offsetWidth
                this.viewportSize.height = this.$refs.viewport.offsetHeight
            })
        },
        layoutSignature() {
            return (
                this.desktopActive.toString() +
                this.webcamActive.toString() +
                this.$refs["desktop-video"].videoWidth +
                this.$refs["webcam-video"].videoWidth +
                this.$refs["desktop-video"].videoHeight +
                this.$refs["webcam-video"].videoHeight +
                this.settings.background +
                this.settings.aspectRatio
            )
        },
        render() {
            requestAnimationFrame(async () => {
                const canvas = this.$refs.canvas
                if (!canvas || canvas == null) return
                const ctx = canvas.getContext("2d")
                const layoutSignature = this.layoutSignature()
                if (this.lastActiveStreams !== layoutSignature) {
                    await this.addBackground(ctx)
                    await this.addLogo(ctx)
                }

                if (this.desktopActive) await this.renderDesktop(ctx)
                if (this.webcamActive) await this.renderWebcam(ctx)

                this.lastActiveStreams = layoutSignature
                if (this.desktopActive || this.webcamActive) {
                    requestAnimationFrame(() =>
                        setTimeout(this.render, 1000 / 60)
                    )
                }
            })
        },
        renderDesktop() {
            const video = this.$refs["desktop-video"]
            const canvas = this.$refs.canvas
            const srcRatio =
                (video.videoWidth || this.aspectRatio.width) /
                (video.videoHeight || this.aspectRatio.height)
            const canvasRatio = canvas.width / canvas.height

            const size =
                canvasRatio > srcRatio
                    ? {
                        width:
                              (canvas.height - this.settings.screenPadding) *
                              srcRatio,
                        height: canvas.height - this.settings.screenPadding,
                    }
                    : {
                        width: canvas.width - this.settings.screenPadding,
                        height:
                              (canvas.width - this.settings.screenPadding) /
                              srcRatio,
                    }
            canvas
                .getContext("2d")
                .drawImage(
                    video,
                    (canvas.width - size.width) / 2,
                    (canvas.height - size.height) / 2,
                    size.width,
                    size.height
                )
        },
        async renderWebcam(context) {
            const video = this.$refs["webcam-video"]

            const tempCanvas = this.$refs.tempCanvas
            const tmpCtx = tempCanvas.getContext("2d")
            const canvas = this.$refs.canvas
            tempCanvas.width = video.videoWidth
            tempCanvas.height = video.videoHeight
            const srcRatio =
                (video.videoWidth || this.aspectRatio.width) /
                (video.videoHeight || this.aspectRatio.height)
            const canvasRatio = canvas.width / canvas.height

            let webcam
            if (this.settings.mirrorWebcam) {
                tmpCtx.drawImage(
                    video,
                    0,
                    0,
                    tempCanvas.width,
                    tempCanvas.height
                )
                this.mirror()
                webcam = this.$refs.mirror
            } else {
                webcam = video
            }

            let size = {}
            if (this.desktopActive) {
                size =
                    canvasRatio > srcRatio
                        ? { width: 240 * srcRatio, height: 240 }
                        : { width: 240, height: 240 / srcRatio }
                size.x = canvas.width - size.width - 16
                size.y = canvas.height - size.height - 16
            } else {
                size =
                    canvasRatio > srcRatio
                        ? {
                            width:
                                  (canvas.height -
                                      this.settings.screenPadding) *
                                  srcRatio,
                            height:
                                  canvas.height - this.settings.screenPadding,
                        }
                        : {
                            width: canvas.width - this.settings.screenPadding,
                            height:
                                  (canvas.width - this.settings.screenPadding) /
                                  srcRatio,
                        }
                size.x = (canvas.width - size.width) / 2
                size.y = (canvas.height - size.height) / 2
            }
            context.drawImage(webcam, size.x, size.y, size.width, size.height)
        },
        mirror() {
            if (!this.$refs.tempCanvas.width) return
            const imageData = this.$refs.tempCanvas
                .getContext("2d")
                .getImageData(
                    0,
                    0,
                    this.$refs.tempCanvas.width,
                    this.$refs.tempCanvas.height
                )

            const mirrored = new Uint8ClampedArray(imageData.data.length)
            const w = imageData.width * 4

            for (let pos = 0; pos < imageData.data.length; pos += 4) {
                const pixels = imageData.data.slice(pos, pos + 4)

                const start = (Math.floor(pos / w) * 2 + 1) * w - pos - 4

                mirrored[start] = pixels[0]
                mirrored[start + 1] = pixels[1]
                mirrored[start + 2] = pixels[2]
                mirrored[start + 3] = pixels[3]
            }
            this.$refs.mirror.width = imageData.width
            this.$refs.mirror.height = imageData.height

            const newImgData = new ImageData(
                mirrored,
                imageData.width,
                imageData.height
            )
            this.$refs.mirror.getContext("2d").putImageData(newImgData, 0, 0)
        },
        async addLogo(ctx) {
            const img = this.logoImg
            await new Promise((resolve) => {
                img.onload = resolve
                img.src = this.logo
            })
            ctx.drawImage(img, 0, 0, 60, 60)
        },
        async addBackground(ctx) {
            const img = this.backgroundImg
            const canvas = this.aspectRatio
            await new Promise((resolve) => {
                img.onload = resolve
                img.src = this.background
            })
            const srcRatio =
                (img.width || this.aspectRatio.width) /
                (img.height || this.aspectRatio.height)
            const canvasRatio = canvas.width / canvas.height
            const size =
                canvasRatio < srcRatio
                    ? { width: canvas.height * srcRatio, height: canvas.height }
                    : { width: canvas.width, height: canvas.width / srcRatio }
            ctx.drawImage(
                img,
                -(size.width - canvas.width) / 2,
                -(size.height - canvas.height) / 2,
                size.width,
                size.height
            )
        },
        async startRecording() {
            logEvent(analytics, "start recording", {
                ...this.settings,
            })
            const finalStream = new MediaStream()
            if (this.audio.state.matches("active")) {
                getTracks(this.audio.state.context.stream, "audio").forEach(
                    function (track) {
                        finalStream.addTrack(track)
                    }
                )
            }
            getTracks(this.$refs.canvas.captureStream(), "video").forEach(
                function (track) {
                    finalStream.addTrack(track)
                }
            )

            const recorder = RecordRTC(finalStream, {
                type: "video",
                mimeType: "video/webm",
            })

            this.setRecorder(recorder)

            if (this.settings.startCountdown) {
                this.messaging = "Get ready! 🤩 🚦"
                await new Promise((resolve) => setTimeout(resolve, 2000))
                this.messaging = "Starting in 3 🔴 "

                await new Promise((resolve) => setTimeout(resolve, 1000))
                this.messaging = "Starting in 2 🟡"

                await new Promise((resolve) => setTimeout(resolve, 1000))
                this.messaging = "Starting in 1 🟢"

                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve()
                        recorder.startRecording()
                        this.messaging = "Go! 📸"
                        this.timerStart()
                        setTimeout(() => {
                            this.messaging = ""
                        }, 1000)
                    }, 1000)
                })
            } else {
                this.messaging = "Get ready!"
                return new Promise((resolve) => {
                    resolve()
                    recorder.startRecording()
                    this.messaging = "SunnyBanana 🤩"
                    this.timerStart()
                    setTimeout(() => {
                        this.messaging = ""
                    }, 1000)
                })
            }
        },
        timerStart() {
            this.startTime = Date.now()
            this.tick()
        },
        tick() {
            this.elapsedTime = Date.now() - this.startTime
            setTimeout(() => {
                if (this.record.state.matches("recording")) this.tick()
            }, 1000)
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-header {
    ._header {
        height: 60px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        padding-left: 16px;
    }
}

._body {
    padding: 0 16px 36px 16px;
    padding-bottom: 36px;

    .form-element {
        box-shadow: inset 0 1px 0 0 #e2e3e4;
        padding: 12px 0;
        .group {
            display: flex;
            align-items: center;
            .label {
                flex: 1;
                display: flex;
                align-self: center;
                align-items: center;
                padding: 0 12px;
            }
            svg {
                color: #66707a;
            }
        }
    }
}

.page {
    -webkit-font-smoothing: antialiased;
    background: #202124;
    font-size: 12px;
    min-height: 100vh;
    margin: 0;
    width: 100vw;
    overflow: hidden;
    color: white;
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        padding: 0 8px;
    }
    .main {
        display: flex;
        flex: 1;
        background: #111111;
        @media (max-width: 935px) {
            flex-direction: column;
        }

        .preview-wrapper {
            flex-grow: 1;
            overflow: hidden;
            display: flex;
            justify-content: center;
            flex-direction: column;

            &.hide {
                opacity: 0;
            }

            video {
                position: absolute;
                display: none;
            }
            canvas {
                background: #000000;
                margin: auto;
                transform-origin: center center;
                transform: scale(var(--scale));
                margin-left: var(--adjustX);
                margin-top: 0;
                border-radius: 16px;
                position: absolute;
            }
        }
        .transcript {
            padding-left: 32px;
            width: 36%;
            max-width: 800px;
        }
    }
    .prepare {
        flex: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 36px;
        margin-top: 80px;
        border-radius: 12px;
        max-width: 360px;
        margin: auto;
        font-size: 1.1rem;
        p {
            padding: 12px 0;
        }
        .button {
            margin-top: 16px;
            padding: 16px 24px;
            font-size: 16px;
        }
    }
    footer {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        button {
            margin: 0 6px;
            height: 40px !important;
            position: relative;
            // display: flex;
            // justify-content: center;

            &:hover::after {
                content: var(--label);
                position: absolute;
                white-space: nowrap;
                top: -32px;
                font-size: 15px;
                height: 28px;
                padding: 0 12px;
                border-radius: 6px;
                color: #ffffff;
                background: #202122;
                box-shadow: 0 0 1px 0 #999;
                display: flex;
                align-items: center;
            }
            &.icon {
                width: 40px !important;
                background: #3c4043;
                &.active {
                    background: #1976d2;
                }
            }
            &.recording-button {
                border-radius: 20px;
                padding: 0 16px 0 8px;
                font-size: 16px;
                text-decoration: none;
                margin-left: 16px;
                background: #f44336;
                background: #c00;
            }
        }
    }
}

.timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .timer {
        width: 96px;
        padding: 0 12px;
    }
}

.back-button {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 4px;
    gap: 8px;
}
</style>
