<template>
    <div class="select">
        <select
            :value="modelValue"
            @input="change"
            :required="required"
        >
            <option
                v-for="(value, key) of options"
                :value="value"
                :key="key"
            >{{ isArray ? value : key }}</option>
        </select>
        <span class="focus"></span>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: String,
        options: Object,
        required: Boolean
    },
    emits: ['update:modelValue'],
    computed: {
        isArray () {
            return Array.isArray(this.options)
        }
    },
    methods: {
        change ($event) {
            this.$emit('update:modelValue', $event.target.value)
        }
    }
}
</script>
<style lang="scss" scoped>
select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 24px 0 8px;
    margin: 0;
    width: 100%;
    height: 36px;
    font-family: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    &::-ms-expand {
        display: none;
    }
}

.select {
    --select-border: #787b80;
    --select-focus: #1265d1;
    --select-arrow: var(--select-border);

    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;

    select,
    &::after {
        grid-area: select;
    }

    min-width: 15ch;

    border-radius: 3px;

    font-size: 15px;
    cursor: pointer;
    line-height: 1.1;

    // Optional styles
    // remove for transparency
    // background-color: #fff;
    // background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    background: #f6f8f9;

    // Custom arrow
    &:not(.select--multiple)::after {
        content: "";
        justify-self: end;
        width: 0.8em;
        height: 0.5em;
        margin-right: 8px;
        background-color: var(--select-arrow);
        clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        pointer-events: none;
    }
}

// Interim solution until :focus-within has better support
select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
    pointer-events: none;
}
</style>
