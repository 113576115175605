<template>
    <div class="wrapper">
        <div
            v-for="(v, i) in tabs"
            :key="i"
            class="dot"
            :class="{ visited: i <= visited }"
        />
    </div>
</template>
<script>
export default {
    name: "Dot Nav",
    props: {
        tabs: Array,
        active: String,
    },
    computed: {
        visited() {
            return this.tabs.indexOf(this.active)
        },
    },
}
</script>
<style lang="scss" scoped>
.wrapper {
    display: flex;
    gap: 8px;
}
.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ddd;
    &.visited {
        background: #1a5cff;
    }
}
</style>