<template>
    <span class="tag"><slot></slot></span>
</template>
<style lang="scss" scoped>
.tag {
    display: inline-flex;
    margin: 4px;
    border: 1px solid #ddd;
    padding: 0 12px;
    border-radius: 12px;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &.active {
        background: aquamarine;
    }
}
</style>