<template>
    <input type="checkbox" ref="input" :checked="modelValue"/>
    <label @click="$emit('click')">
        <span><Icon v-if="indeterminate" :icon="icons.bubbleLoading" height="12"/></span>
    </label>
</template>
<script>
import { Icon } from '@iconify/vue'
import bubbleLoading from '@iconify-icons/eos-icons/bubble-loading'
export default {
    components: {
        Icon
    },
    props: {
        modelValue: Boolean,
        required: Boolean,
        indeterminate: Boolean
    },
    emits: ['click'],
    data () {
        return {
            icons: {
                bubbleLoading
            }
        }
    },
    watch: {
        indeterminate (value) {
            if (this.$refs.input) {
                this.$refs.input.indeterminate = value
            }
        }
    },
    mounted () {
        if (this.indeterminate && this.$refs.input) {
            this.$refs.input.indeterminate = true
        }
    }
}
</script>
<style lang="scss" scoped>
input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
}

label {
    --height: 18px;
    cursor: pointer;
    text-indent: -9999px;
    width: calc(var(--height) * 2);
    height: var(--height);
    background: #b0b2b5;
    display: flex;
    border-radius: var(--height);
    position: relative;
    align-items: center;
    justify-content: center;
}

label span {
    content: '';
    position: absolute;
    left: calc(var(--height) / -20);
    width: calc(var(--height) * 1.2);
    height: calc(var(--height) * 1.2);
    background: #fff;
    border-radius: calc(var(--height) * 1.2);
    transition: 0.16s;
    box-shadow: 0 0 3px #787b80;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        display: block;
        z-index: 1;
        transform-origin: center;
    }
}

label:hover:after {
    width: calc(var(--height) * 1.3);
    height: calc(var(--height) * 1.3);
}

input:checked + label {
    background: #1265d1;
}

input:checked + label span {
    left: calc(100% + calc(var(--height) / 20));
    transform: translateX(-100%);
}

input:indeterminate + label span {
    left: calc(50% + calc(var(--height) / 20));
    transform: translateX(-50%);
}

label:active:after {
    width: calc(var(--height) * 1.4);
}

</style>
