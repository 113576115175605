export default {
    '1280 x 720': {
        width: 1280,
        height: 720
    },
    '720 x 900': {
        width: 720,
        height: 900
    },
    '600 x 900': {
        width: 600,
        height: 900
    },
    '640 x 640': {
        width: 640,
        height: 640
    }
}
