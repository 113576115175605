<template>
    <div class="auto-transcribe">
        <h3>Listening</h3>
        <div v-for="(line, i) of transcript" :key="i">{{ line }}</div>
        <div>...</div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
    name: "AutoTranscribe",
    props: {
        recording: Boolean,
    },
    mounted() {
        this.startListening()
    },
    data() {
        return {
            service: null,
            listening: false,
            transcript: [],
        }
    },
    watch: {
        recording(recording) {
            if (recording && !this.listening) {
                this.service.start()
            }
        },
    },
    beforeUnmount() {
        this.setTranscript()
    },
    methods: {
        ...mapActions(["setTranscript"]),
        startListening() {
            try {
                var SpeechRecognition =
                    window.SpeechRecognition || window.webkitSpeechRecognition
                var recognition = (this.service = new SpeechRecognition())
            } catch (e) {
                console.error(e)
            }

            recognition.continuous = true
            recognition.interimResults = true

            recognition.onaudioend = (onaudioend) => {
                console.log({ onaudioend })
            }
            recognition.onaudiostart = (onaudiostart) => {
                console.log({ onaudiostart })
            }
            recognition.onend = (onend) => {
                this.listening = false
                console.log({ onend })
                if (this.recording) recognition.start()
            }
            recognition.onerror = (onerror) => {
                console.log({ onerror })
            }
            recognition.onnomatch = (onnomatch) => {
                console.log({ onnomatch })
            }
            recognition.onresult = (onresult) => {
                console.log({
                    onresult,
                    map: Object.values(onresult.results).map(
                        (res) => res[0].transcript
                    ),
                })

                this.transcript = Object.values(onresult.results).map(
                    (res) => res[0].transcript
                )
            }
            recognition.onsoundend = (onsoundend) => {
                console.log({ onsoundend })
            }
            recognition.onsoundstart = (onsoundstart) => {
                this.listening = true
                console.log({ onsoundstart })
            }
            recognition.onspeechend = (onspeechend) => {
                console.log({ onspeechend })
            }
            recognition.onspeechstart = (onspeechstart) => {
                console.log({ onspeechstart })
            }
            recognition.onstart = (onstart) => {
                console.log({ onstart })
            }

            recognition.start()
        },
    },
}
</script>

<style lang="scss" scoped>
.auto-transcribe {
    h3 {
        padding: 16px 0;
    }
}
</style>
