<template>
    <div class="onboarding">
        <w-modal
            :open="tab === 'start'"
            @close="close"
            floatingClose
            dark
            @backgroundClick="close"
        >
            <template #default>
                <img src="@/assets/onboarding/start.gif" />
                <div class="onboarding-body">
                    <h2>
                        Every recording starts with showing your face, screen
                        and/or your voice
                    </h2>
                </div>
            </template>
            <template #modal-footer>
                <div class="navigation">
                    <w-button class="secondary"> </w-button>
                    <dot-nav :tabs="tabs" :active="tab" />
                    <w-button @click="next">Next</w-button>
                </div>
                <div class="navigation center">
                    <label>
                        <input
                            type="checkbox"
                            name="unsubsribe"
                            v-model="unsubOnboarding"
                        />
                        Do not ask again
                    </label>

                    <w-button @click="close" class="secondary inline-text">
                        Skip tour
                    </w-button>
                </div>
            </template>
        </w-modal>
        <w-modal :open="tab === 'customize'" @close="close" floatingClose dark>
            <template #default>
                <video
                    src="@/assets/onboarding/settingsOnboarding.mp4"
                    mute
                    loop
                    autoplay="true"
                />
                <div class="onboarding-body">
                    <h2>Personalize your scene with more settings</h2>
                </div>
            </template>
            <template #modal-footer>
                <div class="navigation">
                    <w-button @click="back" class="secondary">Back</w-button>
                    <dot-nav :tabs="tabs" :active="tab" />
                    <w-button @click="next">Next</w-button>
                </div>
                <div class="navigation center">
                    <label>
                        <input
                            type="checkbox"
                            name="unsubsribe"
                            v-model="unsubOnboarding"
                        />
                        Do not ask again
                    </label>

                    <w-button @click="close" class="secondary inline-text">
                        Skip tour
                    </w-button>
                </div>
            </template>
        </w-modal>
        <w-modal :open="tab === 'useCase'" @close="close" floatingClose>
            <template #default>
                <div class="onboarding-body">
                    <h2>Now what will you record?</h2>
                    <p>Tell us what you want to make.</p>
                    <p>
                        <w-tag
                            @click="toggleTag('📈 Presentations')"
                            :class="{
                                active: tags.includes('📈 Presentations'),
                            }"
                            color="black"
                        >
                            <span class="emoji">📈</span> Presentations
                        </w-tag>
                        <w-tag
                            @click="toggleTag('✍️ School projects')"
                            :class="{
                                active: tags.includes('✍️ School projects'),
                            }"
                            color="black"
                        >
                            <span class="emoji">✍️</span> School projects
                        </w-tag>
                        <w-tag
                            @click="toggleTag('👾 Bug reports')"
                            :class="{
                                active: tags.includes('👾 Bug reports'),
                            }"
                            color="black"
                        >
                            <span class="emoji">👾</span> Bug reports
                        </w-tag>
                        <w-tag
                            @click="toggleTag('👋 New hire onboarding')"
                            :class="{
                                active: tags.includes('👋 New hire onboarding'),
                            }"
                            color="black"
                        >
                            <span class="emoji">👋</span> New hire onboarding
                        </w-tag>
                        <w-tag
                            @click="toggleTag('💸 Sales pitches')"
                            :class="{
                                active: tags.includes('💸 Sales pitches'),
                            }"
                            color="black"
                        >
                            <span class="emoji">💸</span> Sales pitches
                        </w-tag>
                        <w-tag
                            @click="toggleTag('🖥 Tutorials')"
                            :class="{
                                active: tags.includes('🖥 Tutorials'),
                            }"
                            color="black"
                        >
                            <span class="emoji">🖥</span> Tutorials
                        </w-tag>
                        <w-tag
                            @click="toggleTag('📖 Educational videos')"
                            :class="{
                                active: tags.includes('📖 Educational videos'),
                            }"
                            color="black"
                        >
                            <span class="emoji">📖</span> Educational videos
                        </w-tag>
                        <w-tag
                            @click="toggleTag('🎓 Lessons')"
                            :class="{
                                active: tags.includes('🎓 Lessons'),
                            }"
                            color="black"
                        >
                            <span class="emoji">🎓</span> Lessons
                        </w-tag>
                        <w-tag
                            @click="toggleTag('🚀 Product Demos')"
                            :class="{
                                active: tags.includes('🚀 Product Demos'),
                            }"
                            color="black"
                        >
                            <span class="emoji">🚀</span> Product Demos
                        </w-tag>
                        <w-tag
                            @click="toggleTag('📣 Internal comms')"
                            :class="{
                                active: tags.includes('📣 Internal comms'),
                            }"
                            color="black"
                        >
                            <span class="emoji">📣</span> Internal comms
                        </w-tag>
                        <w-tag
                            @click="toggleTag('🤔 Something else!')"
                            :class="{
                                active: tags.includes('🤔 Something else!'),
                            }"
                            color="black"
                        >
                            <span class="emoji">🤔</span> Something else!
                        </w-tag>
                    </p>
                    <small>Select an option to continue</small>
                </div>
            </template>
            <template #modal-footer>
                <div class="navigation">
                    <w-button @click="back" class="secondary">Back</w-button>
                    <dot-nav :tabs="tabs" :active="tab" />
                    <w-button @click="close" :disabled="!tags.length">
                        Let's go! <small>🚀</small>
                    </w-button>
                </div>
                <div class="navigation center">
                    <label>
                        <input
                            type="checkbox"
                            name="unsubsribe"
                            v-model="unsubOnboarding"
                        />
                        Do not ask again
                    </label>

                    <w-button @click="close" class="secondary inline-text">
                        Skip tour
                    </w-button>
                </div>
            </template>
        </w-modal>
    </div>
</template>

<script>
import WModal from "@/components/wModal.vue"
import wButton from "@/components/wButton.vue"
import wTag from "@/components/wTag.vue"
import DotNav from "@/components/DotNav.vue"
import { getAnalytics, logEvent } from "firebase/analytics"

const analytics = getAnalytics()
export default {
    name: "Record Onboarding",
    components: { WModal, wButton, wTag, DotNav },
    data() {
        return {
            tabs: ["start", "customize", "useCase"],
            tab: localStorage.getItem("unsubOnboarding") === "true" || "start",
            tags: [],
            unsubOnboarding: false,
        }
    },
    watch: {
        unsubOnboarding(checked) {
            localStorage.setItem("unsubOnboarding", checked)
        },
    },
    methods: {
        close() {
            this.tab = null
        },
        back() {
            const index = this.tabs.indexOf(this.tab) - 1
            this.tab = this.tabs[index]
        },
        next() {
            const index = this.tabs.indexOf(this.tab) + 1
            this.tab = this.tabs[index]
        },
        toggleTag(tag) {
            if (this.tags.includes(tag)) {
                this.tags = this.tags.filter((t) => t != tag)
            } else {
                this.tags.push(tag)
                logEvent(analytics, "uc-" + tag)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep(.modal .modal-frame) {
    max-width: 480px;
    img,
    video {
        width: 100%;
    }
    .onboarding-body {
        padding: 36px 16px;
        text-align: center;
        h2 {
            font-weight: 400;
        }

        p {
            margin-top: 24px;
            font-size: 16px;
        }

        small {
            display: block;
            margin-top: 36px;
            margin-bottom: -24px;
            font-size: 12px;
        }

        .button.tag {
            background: white;
        }
    }

    .navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px 12px 16px;
        &.center {
            justify-content: center;
        }
        .button {
            padding: 0 16px;
            font-size: 16px;
            min-width: 120px;
            // width: 50%;
            &:not(:empty) {
                height: 36px;
            }
            &.secondary {
                color: #1a5cff;
                background: white;
            }
        }
        label {
            margin-left: 24px;
        }
    }
}
</style>