import { $snackBars } from '@/services/snackBars'
import { useMachine } from '@xstate/vue'
import { createMachine, assign } from 'xstate'

function videoError () {
    $snackBars.set('videoError', {
        message: `Fail to start camera, please allow permision to access camera. <br>
If you are browsing through social media built in browsers, you would need to open the page in Sarafi (iPhone)/ Chrome (Android)`,
        action: {
            label: 'Got it 👍',
            fn: () => { $snackBars.delete('videoError') }
        }
    })
}

const feedMachinefactory = (id) => ({
    id,
    initial: 'off',
    states: {
        off: {
            on: {
                TOGGLE: 'starting'
            }
        },
        starting: {
            invoke: {
                src: 'start',
                onDone: {
                    target: 'active',
                    actions: assign({
                        stream: (_, { data }) => data
                    })
                },
                onError: 'off'
            }
        },
        active: {
            on: {
                TOGGLE: 'stopping'
            }
        },
        stopping: {
            invoke: {
                src: 'stop',
                onDone: {
                    target: 'off',
                    actions: assign({ stream: null })
                },
                onError: 'active'
            }
        }
    }
})

function startStream ({ id, start, type }) {
    return useMachine(
        createMachine(feedMachinefactory(id), {
            services: {
                start,
                stop: type === 'audio'
                    ? async (ctx) =>
                        ctx.stream.getTracks().forEach((track) => track.stop())
                    : async (ctx) =>
                        ctx.stream
                            .getVideoTracks()
                            .forEach((track) => track.stop())
            }
        })
    )
}

export default () => ({
    audio: startStream({
        id: 'audio',
        start: () =>
            navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppresion: true,
                    sampleRate: 44100
                }
            }),
        type: 'audio'
    }),
    webcam: startStream({
        id: 'webcam',
        start: () =>
            navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            }).catch(error => {
                videoError(error)
                throw error
            }),
        type: 'video'
    }),
    desktop: startStream({
        id: 'desktop',
        start: () =>
            navigator.mediaDevices.getDisplayMedia({
                video: { mediaSource: 'screen' }
            }).catch(error => {
                videoError(error)
                throw error
            }),
        type: 'video'
    })
})
