<template>
    <transition name="fade">
        <div v-if="open" class="modal-background"></div>
    </transition>
    <transition name="slide-fade">
        <div
            v-if="open"
            class="modal"
            :class="{ open, [modalClass]: modalClass }"
            @click="backgroundOnClick"
        >
            <div class="modal-frame" @click.stop="() => {}" v-bind="$attrs">
                <span v-if="floatingClose" class="floatingClose">
                    <wClose @click="$emit('close')" :class="{ white: dark }" />
                </span>
                <div class="modal-header" :class="{ floatingClose }">
                    <slot name="modal-header"></slot>
                </div>
                <div class="modal-body"><slot name="default"></slot></div>
                <div class="modal-footer">
                    <slot name="modal-footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import wClose from "@/components/wClose"
export default {
    components: {
        wClose,
    },
    props: {
        modalClass: String,
        open: Boolean,
        floatingClose: Boolean,
        dark: Boolean,
    },
    emits: ["backgroundClick", "close"],
    methods: {
        backgroundOnClick() {
            this.$emit("backgroundClick")
        },
    },
}
</script>
<style lang="scss" scoped>
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

span.floatingClose {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1;
}

.modal {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 720px) {
    }

    .modal-frame {
        position: relative;
        width: 100%;
        max-height: 100%;
        display: flex;
        max-width: 700px;
        flex-direction: column;
        background: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 16px 0px;
        border-radius: 6px;

        .modal-header {
            flex-shrink: 0;

            &.floatingClose {
                margin-right: 36px;
            }
        }
        .modal-body {
            overflow: auto;
        }
        .modal-footer {
            flex-shrink: 0;
        }
    }
}
</style>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.2s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
